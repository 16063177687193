a:hover, a:focus {
  color: #ad3a42;
}

.wrapper-home {
  padding: 2rem 1rem;
}

.off-canvas-wrapper {
    background-color: $body-background-color;
}
.home-section:nth-of-type(2n+1) {
	background-color: $body-background-color;
}

.home-section {
	&.hero, &.highligted-content-banner {
	  @include border-waves($body-background-color, transparent, $wave-size, "up");
	  margin-left: -($wave-size);
	  padding-left: $wave-size;

	  &::after {
	  	background-color: transparent;
	  }

	  >.hero__container, >.highligted-content-banner__container {
	  	@include border-waves(transparent, $body-background-color, $wave-size, "down");
	  }
	}
}
