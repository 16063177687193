span.select2-container--foundation {
  vertical-align: top; }

span.select2-container--foundation .selection {
  margin: 0 0 1rem; }

.select2-container--foundation {
  /**
   * Search field in the Select2 dropdown.
   */
  /**
   * No outline for all search fields - in the dropdown
   * and inline in multi Select2s.
   */
  /* Clear the selection. */
  /**
   * Address disabled Select2 styles.
   *
   * @see https://select2.github.io/examples.html#disabled
   * @see http://getbootstrap.com/css/#forms-control-disabled
   */
  /*------------------------------------*\
          #DROPDOWN
  \*------------------------------------*/ }
  .select2-container--foundation .select2-selection {
    background-color: #fefefe;
    border: 1px solid #cacaca;
    border-radius: 0;
    color: #0a0a0a;
    font-family: inherit;
    font-size: 1rem;
    background: #fefefe;
    outline: 0; }
  .select2-container--foundation .select2-search--dropdown .select2-search__field {
    background-color: #fefefe;
    border: 1px solid #cacaca;
    border-radius: 0;
    color: #0a0a0a;
    font-family: inherit;
    font-size: 1rem;
    border-radius: 0; }
  .select2-container--foundation .select2-search__field {
    outline: 0;
    /* Firefox 18- */
    /**
     * Firefox 19+
     *
     * @see http://stackoverflow.com/questions/24236240/color-for-styled-placeholder-text-is-muted-in-firefox
     */ }
    .select2-container--foundation .select2-search__field::-webkit-input-placeholder {
      color: #8a8a8a; }
    .select2-container--foundation .select2-search__field:-moz-placeholder {
      color: #8a8a8a; }
    .select2-container--foundation .select2-search__field::-moz-placeholder {
      color: #8a8a8a;
      opacity: 1; }
    .select2-container--foundation .select2-search__field:-ms-input-placeholder {
      color: #8a8a8a; }
  .select2-container--foundation .select2-results__option {
    /**
     * Disabled results.
     *
     * @see https://select2.github.io/examples.html#disabled-results
     */
    /**
     * Hover state.
     */
    /**
     * Selected state.
     */ }
    .select2-container--foundation .select2-results__option[role=group] {
      padding: 0; }
      .select2-container--foundation .select2-results__option[role=group] strong {
        padding-left: 0.125rem; }
    .select2-container--foundation .select2-results__option[aria-disabled=true] {
      color: #8a8a8a;
      cursor: default; }
    .select2-container--foundation .select2-results__option--highlighted[aria-selected] {
      background-color: #2199e8;
      color: foreground(#2199e8); }
  .select2-container--foundation.select2-container--focus .select2-selection {
    border: 1px solid #8a8a8a; }
  .select2-container--foundation.select2-container--open .select2-selection {
    border: 1px solid #8a8a8a;
    border-radius: 0; }
  .select2-container--foundation.select2-container--open {
    /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */ }
    .select2-container--foundation.select2-container--open .select2-selection__arrow b {
      transform: rotate(180deg); }
  .select2-container--foundation .select2-choice {
    box-shadow: none;
    background-image: none; }
    .select2-container--foundation .select2-choice div {
      background-clip: padding-box; }
    .select2-container--foundation .select2-choice .select2-arrow {
      border: 0px;
      border-radius: 0px;
      background: transparent;
      background-image: none; }
  .select2-container--foundation *:focus {
    outline: 0px; }
  .select2-container--foundation .select2-selection__clear {
    color: #8a8a8a;
    cursor: pointer;
    float: left;
    font-weight: bold;
    margin-right: 0px; }
    .select2-container--foundation .select2-selection__clear:hover {
      color: #333; }
  .select2-container--foundation.select2-container--disabled .select2-selection {
    border: 1px solid #cacaca; }
  .select2-container--foundation.select2-container--disabled .select2-selection,
  .select2-container--foundation.select2-container--disabled .select2-search__field {
    cursor: default; }
  .select2-container--foundation.select2-container--disabled .select2-selection,
  .select2-container--foundation.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
    background-color: #e6e6e6;
    color: foreground(#e6e6e6); }
  .select2-container--foundation.select2-container--disabled .select2-selection__clear,
  .select2-container--foundation.select2-container--disabled .select2-selection--multiple .select2-selection__choice__remove {
    display: none; }
  .select2-container--foundation .select2-dropdown {
    border: 1px solid #8a8a8a;
    border-top: 1px solid #cacaca;
    background: #fefefe;
    border-radius: 0;
    overflow-x: hidden;
    margin-top: -0.0625rem; }
    .select2-container--foundation .select2-dropdown--above {
      margin-top: 0.0625rem;
      border-radius: 0;
      border-top: 1px solid #8a8a8a;
      border-bottom: 1px solid #cacaca; }
  .select2-container--foundation .select2-results > .select2-results__options {
    /* Limit the dropdown height. */
    max-height: 200px;
    overflow-y: auto; }

/**
 * Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
 *
 * Provides `!important` for certain properties of the class applied to the
 * original `<select>` element to hide it.
 *
 * @see https://github.com/select2/select2/pull/3301
 * @see https://github.com/fk/select2/commit/31830c7b32cb3d8e1b12d5b434dee40a6e753ada
 */
.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important; }

/**
 * Display override for inline forms
 */
.input-group span.select2-container--foundation {
  margin: 0;
  vertical-align: middle; }
  .input-group span.select2-container--foundation .selection .select2-selection {
    vertical-align: middle;
    border-radius: 0; }

/****** Single SCSS *******/
.select2-container--foundation .select2-selection--single,
.select2-container--foundation .selection .select2-selection--single {
  height: 2.4375rem;
  line-height: 1.5;
  padding: 0.5rem; }
  .select2-container--foundation .select2-selection--single .select2-selection__rendered,
  .select2-container--foundation .selection .select2-selection--single .select2-selection__rendered {
    color: #0a0a0a; }
  .select2-container--foundation .select2-selection--single .select2-selection__arrow,
  .select2-container--foundation .selection .select2-selection--single .select2-selection__arrow {
    height: 2.4375rem;
    position: absolute;
    top: 0;
    right: 1px;
    width: 20px; }
    .select2-container--foundation .select2-selection--single .select2-selection__arrow b,
    .select2-container--foundation .selection .select2-selection--single .select2-selection__arrow b {
      border-color: #333 transparent transparent transparent;
      top: 50%;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      width: 0; }
  .select2-container--foundation .select2-selection--single .select2-selection__placeholder,
  .select2-container--foundation .selection .select2-selection--single .select2-selection__placeholder {
    color: #8a8a8a; }
  .select2-container--foundation .select2-selection--single .select2-selection__clear,
  .select2-container--foundation .selection .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }

/****** Multiple SCSS *******/
.select2-container--foundation .select2-selection--multiple {
  /* Clear the selection.  */ }
  .select2-container--foundation .select2-selection--multiple .select2-selection__choice__remove:hover {
    cursor: pointer; }
  .select2-container--foundation .select2-selection--multiple ul.select2-selection__rendered,
  .select2-container--foundation .select2-selection--multiple .select2-selection__rendered {
    display: inherit;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
  .select2-container--foundation .select2-selection--multiple .select2-selection__choice {
    border-radius: 0;
    padding: 0.1875rem 0.3125rem;
    cursor: default;
    float: left;
    margin-right: 0.3125rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    border: 1px solid #8a8a8a; }
  .select2-container--foundation .select2-selection--multiple .select2-selection__choice__remove {
    margin-right: 6px;
    margin-left: 6px;
    float: right; }
  .select2-container--foundation .select2-selection--multiple .select2-search--inline .select2-search__field {
    background: transparent;
    line-height: 1.5;
    border: 0;
    margin-top: 0;
    min-width: 5rem;
    height: 2.375rem;
    box-shadow: none;
    margin: 0;
    margin-right: -2rem; }
  .select2-container--foundation .select2-selection--multiple .select2-selection__choice__remove {
    color: #8a8a8a;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 0.25rem; }
    .select2-container--foundation .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }
  .select2-container--foundation .select2-selection--multiple .select2-selection__clear {
    margin-right: 0.1875rem;
    margin-top: 0.5rem; }

/*# sourceMappingURL=select2-foundation-theme.css.map */
