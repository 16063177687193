.mini-footer{
    background: $body-background-color;
    color: $body-font-color;
}

.footer-transition {
  @include gradient-background($gradient-start, $gradient-end);
  @include border-waves(transparent, $body-background-color, $wave-size);
}

.decidim-logo{
  // width: 100px;
  display: inline-block;
  padding-left: 1em;
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(117deg);
  float: right;
}

.project-logos {
  text-align: center;
  img {
    display: inline-block;
    vertical-align: middle;
    max-width: 150px;
    min-width: 100px;
    width: 25%;
    margin: 0 2em 1em;
    height: auto;
    &:last-child {
      min-width:150px;
    }
  }
}
.cc-badge {
  filter: sepia(1) saturate(3) brightness(70%) hue-rotate(154deg)
}

//Main footer
.main-footer{
  @include gradient-background($gradient-start, $gradient-end);
  @include border-waves($body-background-color, transparent, $wave-size, "up");
  margin-left: -($wave-size);
  padding-left: $wave-size;

  color: #fff;

  a {
    color: #fff;
  }
}
.main-footer a:hover, .mini-footer a:hover {
    color: $link-color;
}