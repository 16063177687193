// Overide background default bar
.title-bar{
  @include gradient-background($gradient-start, $gradient-end);
  border-top-color: transparent;

  @include breakpoint(smallmedium down){
    .language-choose > ul > li > a{
      font-weight: 600;
    }
    @include border-waves(transparent, $body-background-color, $wave-size);
  }
}

@include breakpoint(medium up){
  .topbar__dropmenu > ul > li > a::after {
      border-top-color: $link-color !important;
  }
}

.navbar{
  @include gradient-background($gradient-start, $gradient-end);
  position: relative;
  padding-bottom: $wave-size;

  @include border-waves(transparent, $body-background-color, $wave-size);
}

.main-nav__link {
  margin: 10px;
	a{
	  color: $navbar-fg;
	  font-weight: 600;
		border-radius: 5px;
    box-shadow: none;

	  &:hover{
	    color: $navbar-fg-hover;
	  }
	}
	&--active a {
		box-shadow: none;
		background-color: rgba(0,0,0,0.15);
	}
}
.hide-for-medium{

  .topbar__user__login{
    border-bottom: none;
    border-top: none;

    a{
      color: $secondary;
      font-weight: 600;

      &:hover{
        color: $anchor-color;
      }
    }
  }
}

.off-canvas {
  background: $body-background-color;
  .close-button{
    color: $secondary;
  }
}

.usermenu-off-canvas-holder .usermenu-off-canvas {
  border:none;
   a,a:hover {
    display: block;
    padding: 1em 2em;
    color: $body-font-color;
  }
}

.dropdown.menu .is-active > a{
  color: $link-color;
}

.topbar_{
  &_menu{
    padding: .125rem .5rem 0;
    background: rgba(48, 65, 93, .1);
    border-radius: 12.5%;

    [data-toggle]{
      margin-bottom: 0;
    }

    .icon--menu{
      fill: $secondary;
      width: 1em;
      height: 1em;
    }
  }

  &_edit__link a{
    padding-left: 5px;
    color: $link-color;

    &:hover{
      color: $link-color;
    }
  }

  &_user__login{
    a{
      color: $link-color;
      font-weight: 400;
    }
  }

  &_dropmenu{
    > ul > li > a{
      color: $link-color;
    }

    .is-dropdown-submenu-parent{
      > a,
      &.is-active > a{
        @include breakpoint(smallmedium down){
          color: white;
        }
      }
    }

    .is-dropdown-submenu li a{
      color: $link-color;
    }
  }

  &_search{
    input,
    input:focus{
      background-color: $navbar-bg;
      color: $navbar-fg-hover;
    }

    &:not(:focus-within) button{
      background-color: $secondary;
    }

    input::placeholder,
    textarea::placeholder{
      color: $navbar-fg-hover;
    }

    svg{
      color: white;
    }
  }

  &_notifications,&_conversations{
    .icon{
      opacity: 0.8;
    }
  }
}

.logo-wrapper {
	span {
		color: $body-font-color !important;
		&::before {
			border-color: $body-font-color !important;
		}
	}
}
